var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c(
        "v-sheet",
        {
          staticClass: "mb-7 locale-config",
          attrs: { color: "transparent", "max-width": "550px" },
        },
        [
          _c("mew-select", {
            ref: "mewSelect",
            staticClass: "testing",
            attrs: {
              label: "Currency",
              "filter-placeholder": "Search currency",
              items: _vm.currencies,
              value: _vm.currency,
              "is-custom": true,
            },
            on: { input: _vm.setCurrencyType },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }